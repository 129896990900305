import {
  AppShell,
  CORE_ADMIN,
  CORE_STANDARD,
  ErrorFallback,
  useAppSettings,
} from '@autone/ui';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

//@ts-ignore
import store from './redux/store';
import AuthRoutes from './routes/auth.routes';

function App() {
  const routes = [AuthRoutes];

  // set up app settings - intercom, sentry, mixpanel & fullview
  useAppSettings('auth');

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AppShell
        reduxStore={store}
        routes={routes}
        permissionGroups={[CORE_ADMIN, CORE_STANDARD]}
      />
    </ErrorBoundary>
  );
}

export default App;
